exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-courses-jsx": () => import("./../../../src/pages/courses.jsx" /* webpackChunkName: "component---src-pages-courses-jsx" */),
  "component---src-pages-customer-info-jsx": () => import("./../../../src/pages/customer-info.jsx" /* webpackChunkName: "component---src-pages-customer-info-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-group-gymnasts-contract-jsx": () => import("./../../../src/pages/group-gymnasts-contract.jsx" /* webpackChunkName: "component---src-pages-group-gymnasts-contract-jsx" */),
  "component---src-pages-gymnasts-contract-jsx": () => import("./../../../src/pages/gymnasts-contract.jsx" /* webpackChunkName: "component---src-pages-gymnasts-contract-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-medals-jsx": () => import("./../../../src/pages/medals.jsx" /* webpackChunkName: "component---src-pages-medals-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-policies-jsx": () => import("./../../../src/pages/policies.jsx" /* webpackChunkName: "component---src-pages-policies-jsx" */),
  "component---src-pages-registrations-jsx": () => import("./../../../src/pages/registrations.jsx" /* webpackChunkName: "component---src-pages-registrations-jsx" */),
  "component---src-pages-timetable-jsx": () => import("./../../../src/pages/timetable.jsx" /* webpackChunkName: "component---src-pages-timetable-jsx" */),
  "component---src-templates-discipline-jsx": () => import("./../../../src/templates/discipline.jsx" /* webpackChunkName: "component---src-templates-discipline-jsx" */),
  "component---src-templates-legal-jsx": () => import("./../../../src/templates/legal.jsx" /* webpackChunkName: "component---src-templates-legal-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-team-member-jsx": () => import("./../../../src/templates/teamMember.jsx" /* webpackChunkName: "component---src-templates-team-member-jsx" */)
}

